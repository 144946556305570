//type=2 is for payment status
//type=3 is for coupon code
export const AllStatus = [
    {
      name: "Active",
      value:1,
      type:1      
    },
    {
      name: "Inactive",
      value:0,
      type:1
    },
    {
      name: "Deleted",
      value:4,
      type:1
    },
    {
      name: "Pending",
      value:2,
      type:0
    },
    {
      name: "Blocked",
      value:3,
      type:0
    },
    {
      name: "Admin Blocked",
      value:8,
      type:0
    },
    {
      name: "Reported",
      value:7,
      type:0
    },
    {
      name: "Success",
      value:1,
      type:2
    },
    {
      name: "Pending",
      value:0,
      type:2
    },
    {
      name: "Failed",
      value:2,
      type:2
    },
    {
      name: "In Progress",
      value:3,
      type:2
    },  
    {
      name: "Active",
      value:1,
      type:3      
    },
    {
      name: "Inactive",
      value:0,
      type:3
    },
    {
      name: "Reedemed",
      value:2,
      type:3
    },
    {
      name: "Expired",
      value:3,
      type:3
    }
  ];
  